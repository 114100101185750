import React, { Component } from "react"
import { Helmet } from "react-helmet"
import { ContactForm, Layout } from "../../../components"

import {
  BlockImageTextHalf,
  Medailon,
  PaddingWrapper,
  ReferenceBanner,
  ReferenceContainer,
  ReferenceBannerContent,
} from "@igloonet-web/shared-ui"

import Vaclav from "../../../images/reference/grohe/vaclav.jpg"
import Middle from "../../../images/reference/grohe/mid.jpg"
import Bathroom from "../../../images/reference/grohe/small.jpg"
import Logo from "../../../images/reference/grohe/logo.png"
import Top from "../../../images/reference/grohe/top.png"

class Grohe extends Component {
  constructor({ props }) {
    super(props)
  }

  render() {
    const pozadi = {
      backgroundImage: `linear-gradient(rgba(0,0,0,0.3),rgba(0,0,0,0.9)),url(${Top})`,
    }

    return (
      <Layout>
        <Helmet>
          <title>Reference na marketingové práce pro Grohe | igloonet.cz</title>
          <meta
            name="description"
            content="Konzultace a mentoring interních týmů pro klienty řešíme celkem často,
            ale tato akce pro finanční manažery pro nás byla svým záběrem i krátkou dobou
            na realizaci specifická."
          />
        </Helmet>
        <ReferenceBanner
          styles={pozadi}
          logo={Logo}
          extodkaz="//grohe.cz/cs_cz"
          heading="Grohe: Představení současných e‑commerce trendů pro finanční manažery z Evropských zemí"
          odstavec="únor 2020"
        >
          <ReferenceBannerContent
            mainText="Konzultace a mentoring interních týmů pro klienty řešíme celkem často, ale
          tato akce pro finanční manažery pro nás byla svým záběrem i krátkou dobou na realizaci specifická."
          />
        </ReferenceBanner>

        <ReferenceContainer>
          <PaddingWrapper>
            <BlockImageTextHalf
              className="d-md-none"
              image={Middle}
              alt={"ALT"}
              right
              hideImageOnSmall
            >
              <p>
                Pro Grohe stejně jako pro ostatní tradiční výrobce má online
                svět čím dál tím větší kouzlo. Na pravidelném setkání finančních
                manažerů z evropských poboček Grohe jsme proto představili
                současné trendy ve světě e‑commerce.
              </p>
              <p>
                Tím, že šlo o akci pro finanční manažery, jsme největší důraz
                kladli na vyhodnotitelnost jednotlivých aktivit – nejen na
                úrovni jednoduchého ROI výkonnostních kampaní, ale vyhodnocování
                v kontextu konkurence, brand awereness a share of voice v online
                prostředí.
              </p>
            </BlockImageTextHalf>

            <BlockImageTextHalf
              className="d-md-none"
              image={Bathroom}
              alt={"ALT"}
              left
              hideImageOnSmall
            >
              <p>
                Český tým nejdříve prezentoval své kampaně, kterými ukázal
                možnosti provázání digitálních aktivit s tradiční outdoor formou
                – propojování e‑commerce světa s tím retailovým. My poté
                pokračovali třemi bloky:
              </p>
              <p>
                <ol>
                  <li>možnosti brandových aktivit v rámci digitálu;</li>
                  <li>pokročilé možnosti měření a vyhodnocování;</li>
                  <li>nové trendy v rámci e‑commerce.</li>
                </ol>
              </p>
              <p>
                Akce nám přišla vydařená, tak doufáme, že jsme svou troškou do
                mlýna pomohli s „digitální evolucí“.
              </p>
            </BlockImageTextHalf>
          </PaddingWrapper>

          <PaddingWrapper>
            <Medailon
              image={Vaclav}
              alt=""
              name="Ing. Václav Kužílek"
              position="Marketing Activation Manager CZSK"
            >
              <p>
                Pro společnost GROHE je a vždy bude podstatné udržovat krok a
                tempo nastavené trhem, nejen proto abychom si dokázali udržet
                svou dominantní pozici leadera v oblasti sanity, ale také
                abychom celý obor pomáhali definovat, edukovat a zároveň určovat
                jeho dlouhodobé směřování. I proto jsme se rozhodli spojit síly
                s igloonetem v rámci edukace finačních manažerů GROHE, kteří
                právě nejčastěji rozhodují o&nbsp;strategických investicích.
              </p>
              <p>
                Zdokonalování jakýchkoliv procesů lze dosáhnout pouze tehdy, kdy
                se učíte od těch nejlepších v oboru, a proto byl coach Adam naše
                sázka na jistotu.
              </p>
              <p>
                Školení bylo názorné, interaktivní a postaveno na konkrétních
                datech, proto také sklidilo velký úspěch a my se tešíme na další
                společné výzvy.
              </p>
            </Medailon>
          </PaddingWrapper>
        </ReferenceContainer>

        <ContactForm contact="adam" />
      </Layout>
    )
  }
}

export default Grohe
